//Application Details Page
import React, { useEffect, useState } from "react";
import Sidebar from "../components/SideBar";
import CustomerDetails from "../components/CustomerDetails";
import ProductSummary from "../components/ProductSummary";
import ImageDownloadLink from "../components/ImageDownloadLink";
import ConfirmationDialog from "../components/ConfirmDialogue";
import { API_BASE_URL } from "../config";
import { useNavigate } from "react-router-dom";
import {
  createSubscription,
  fetchApplicationData,
  sendPutRequest,
} from "../helpers/ApplicationService";
import { DownloadIcon } from "@heroicons/react/solid";

export default function ApplicationDetailsPage() {
  const [filterStatus, setFilterStatus] = useState(["PendingApproval"]);
  const [id, setId] = useState(null);
  const [application, setApplication] = useState();
  const [confirmDialogue, setConfirmDialogue] = useState(null);
  const [isConfirmDialogueOpen, setIsConfirmDialogueOpen] = useState(false);
  const [isDeclineDialogueOpen, setIsDeclineDialogueOpen] = useState(false);
  const [itemDetails, setItemDetails] = useState(" ");
  const [confirmColor, setConfirmColor] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    async function fetchData() {
      try {
        const url = window.location.pathname;
        const id = url.substring(url.lastIndexOf("/") + 1);
        setId(id);
        console.log(id);

        if (id != null) {
          const data = await fetchApplicationData(id);
          if (data) {
            setApplication(data);
            console.log(data);
          }
        }
      } catch (error) {
        console.error("Error fetching application:", error);
      }
    }

    fetchData();
  }, [id]);

  const approveApplication = (application) => {
    if (application.applicationStatusEnum === "Approved") {
      alert(
        "warning cannot approve an already approved application to prevent double charging"
      );
      return;
    }

    setConfirmDialogue("Are you sure you want to approve this application?");
    setItemDetails("Application ID: " + application.id);
    setConfirmColor(" bg-green-500");
    setIsConfirmDialogueOpen(true);
  };

  const declineApplication = (application) => {
    setConfirmDialogue("Are you sure you want to decline this application?");
    setItemDetails("Application ID: " + application.id);
    setIsDeclineDialogueOpen(true);
  };

  const handleOnConfirmConfirm = () => {
    setIsConfirmDialogueOpen(false);
    setConfirmColor(null);
    const approveUrl =
      API_BASE_URL + `/api/v1/application/${application.id}/accepted`;
    sendPutRequest(
      approveUrl,
      "Application approved:",
      "Error approving application:"
    )
      .then(() =>
        createSubscription(
          application.id,
          application.productAppliedFor.stripeId,
          application.user.stripeId
        )
      )
      .catch((error) => alert(error));
  };

  const handleOnConfirmDecline = () => {
    setIsDeclineDialogueOpen(false);
    setConfirmColor(null);
    const declineUrl =
      API_BASE_URL + `/api/v1/application/${application.id}/declined`;
    sendPutRequest(
      declineUrl,
      "Application declined:",
      "Error declining application:"
    );
    navigate(-1);
  };

  const handleCancel = () => {
    setIsDeclineDialogueOpen(false);
    setIsConfirmDialogueOpen(false);
    setConfirmColor(null);
  };

  function getPrice(rentalApplication) {
    var price;

    switch (rentalApplication.paymentFrequency.toLowerCase()) {
      case "daily":
        price = rentalApplication.variationAppliedFor.pricePerDay;
        break;
      case "weekly":
        price = rentalApplication.variationAppliedFor.pricePerWeek;
        break;
      case "fortnightly":
        price = rentalApplication.variationAppliedFor.pricePerFortnight;
        break;
      case "monthly":
        price = rentalApplication.variationAppliedFor.pricePerMonth;
        break;
      case "quarterly":
        price = rentalApplication.variationAppliedFor.pricePerQuarter;
        break;
      default:
        throw new Error(
          `String '${rentalApplication.paymentFrequency}' not recognized as a payment frequency`
        );
    }
    return price;
  }

  function downloadFile(base64EncodedFile) {
    if (base64EncodedFile.startsWith("JVB")) {
      //pdf
      openBase64String(base64EncodedFile, "application/pdf");
    } else if (base64EncodedFile.startsWith("/9j")) {
      //jpg
      openBase64String(base64EncodedFile, "image/jpg");
    } else if (base64EncodedFile.startsWith("iVB")) {
      //png
      openBase64String(base64EncodedFile, "image/png");
    }
  }

  function openBase64String(base64String, type) {
    var base64 = base64String;
    const blob = base64ToBlob(base64, type);
    const url = URL.createObjectURL(blob);
    const pdfWindow = window.open("");
    pdfWindow.document.write(
      "<iframe width='100%' height='100%' src='" + url + "'></iframe>"
    );
  }

  function base64ToBlob(base64, type = "application/octet-stream") {
    const binStr = atob(base64);
    const len = binStr.length;
    const arr = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      arr[i] = binStr.charCodeAt(i);
    }
    return new Blob([arr], { type: type });
  }

  function tdown(data) {
    return (
      <td className="px-6 py-4 whitespace-wrap">
        <div className="flex items-center">
          <div className="ml-4">
            <div className="text-sm">{data}</div>
          </div>
        </div>
      </td>
    );
  }

  function thead(data) {
    return (
      <th className="px-6 py-3 text-xs font-medium tracking-wider text-left text-white uppercase bg-gray-800">
        {data}
      </th>
    );
  }

  return (
    <div className="flex flex-col h-screen bg-gray-100">
      <ConfirmationDialog
        isOpen={isConfirmDialogueOpen}
        onCancel={handleCancel}
        onConfirm={handleOnConfirmConfirm}
        confirmDialogue={confirmDialogue}
        itemDetails={itemDetails}
        confirmColor={confirmColor}
      />
      <ConfirmationDialog
        isOpen={isDeclineDialogueOpen}
        onCancel={handleCancel}
        onConfirm={handleOnConfirmDecline}
        confirmDialogue={confirmDialogue}
        itemDetails={itemDetails}
        confirmColor={confirmColor}
      />

      <div className="flex">
        {/* Sidebar */}
        <Sidebar />
        <div className="flex flex-col h-screen bg-gray-100 w-full max-w-7xl">
          <div className="p-6 w-full">
            <h1 className="text-3xl font-bold mb-2">
              Application Details Page
            </h1>
          </div>
          {/* Application ID */}
          <div className="flex flex-col mx-12 mt-5">
            <h2 className="my-4 text-2xl font-semibold">
              Application ID: {application ? application.id : ""}
            </h2>
          </div>
          <div>
            <div className="flex flex-col bg-gray-100">
              <div className="flex flex-col mx-12 mt-5">
                <h2 className="my-4 text-2xl font-semibold">Product Details</h2>
                <div className="flex flex-col mt-8">
                  <div className="py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
                    <div className="inline-block min-w-full overflow-hidden align-middle border-b border-gray-200 shadow sm:rounded-lg">
                      <table className="min-w-full">
                        <thead>
                          <tr>
                            {thead("Product Name")}
                            {thead("Product Price")}
                            {thead("Payment Period")}
                            {thead("Storage")}
                            {thead("Colors")}
                          </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200">
                          <tr>
                            {tdown(
                              application
                                ? application.productAppliedFor.name
                                : ""
                            )}
                            {tdown(
                              application
                                ? getPrice(application)
                                : "unable to get price"
                            )}
                            {tdown(
                              application ? application.paymentFrequency : ""
                            )}
                            {tdown(
                              application
                                ? application.variationAppliedFor.storage
                                : ""
                            )}
                            {tdown(
                              application ? (
                                application.colorAppliedFor != null ? (
                                  <div>
                                    {application.colorAppliedFor.name} (
                                    {application.colorAppliedFor.hex})
                                  </div>
                                ) : (
                                  ""
                                )
                              ) : (
                                ""
                              )
                            )}
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className="flex flex-col bg-gray-100">
              <div className="flex flex-col mx-12 mt-5">
                <h2 className="my-4 text-2xl font-semibold">
                  Customer Details
                </h2>
                <div className="flex flex-col mt-8 ">
                  <div className="py-2 sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
                    <div className="inline-block min-w-full align-middle border-b border-gray-200 shadow sm:rounded-lg">
                      <table className="min-w-full">
                        <thead>
                          <tr>
                            {thead("Stripe ID")}
                            {thead("Name")}
                            {thead("Email")}
                            {thead("Phone")}
                            {thead("Home Address")}
                            {thead("Delivery Address")}
                          </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200">
                          <tr>
                            {tdown(
                              application ? application.user.stripeId : ""
                            )}
                            {tdown(
                              application
                                ? application.user.firstName +
                                    " " +
                                    application.user.middleName +
                                    " " +
                                    application.user.lastName
                                : ""
                            )}
                            {tdown(application ? application.user.email : "")}
                            {tdown(application ? application.user.phone : "")}
                            {tdown(
                              application
                                ? application.user.homeAddress.street1 +
                                    ", " +
                                    application.user.homeAddress.city +
                                    ", " +
                                    application.user.homeAddress.state +
                                    ", " +
                                    application.user.homeAddress.postal
                                : ""
                            )}
                            {tdown(
                              application
                                ? application.user.deliveryAddress.street1 +
                                    ", " +
                                    application.user.deliveryAddress.city +
                                    ", " +
                                    application.user.deliveryAddress.state +
                                    ", " +
                                    application.user.deliveryAddress.postal
                                : ""
                            )}
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              {/* Download section for POI and ID documents */}
              <div className="flex flex-col mx-12 mt-5">
                <h2 className="my-4 text-2xl font-semibold">Documents</h2>
                <div className="flex flex-col mt-4 lg:grid lg:grid-cols-3 gap-4">
                  {application ? (
                    <div className="lg:col-span-1">
                      <button
                        key={`POI Document for ${application.user.firstName}`}
                        onClick={() =>
                          application.user.proofOfIncomeDocument
                            ? downloadFile(
                                application.user.proofOfIncomeDocument
                              )
                            : null
                        }
                        className={`${
                          application.user.proofOfIncomeDocument
                            ? "bg-blue-500 hover:bg-blue-700 cursor-pointer"
                            : "bg-gray-400 cursor-not-allowed"
                        } text-white font-bold py-2 px-4 rounded flex items-center`}
                        disabled={!application.user.proofOfIncomeDocument}
                      >
                        <DownloadIcon className="w-6 h-6 mr-2" />{" "}
                        {/* Add the download icon */}
                        POI Document for {application.user.firstName}
                      </button>
                    </div>
                  ) : (
                    ""
                  )}
                  <div></div>
                  {application ? (
                    <div className="lg:col-span-1">
                      <button
                        key={`ID Document for ${application.user.firstName}`}
                        onClick={() =>
                          application.user.identificationDocument
                            ? downloadFile(
                                application.user.identificationDocument
                              )
                            : null
                        }
                        className={`${
                          application.user.identificationDocument
                            ? "bg-blue-500 hover:bg-blue-700 cursor-pointer"
                            : "bg-gray-400 cursor-not-allowed"
                        } text-white font-bold py-2 px-4 rounded flex items-center`}
                        disabled={!application.user.identificationDocument}
                      >
                        <DownloadIcon className="w-6 h-6 mr-2" />{" "}
                        {/* Add the download icon */}
                        ID Document for {application.user.firstName}
                      </button>
                    </div>
                  ) : (
                    ""
                  )}
                  {/* The third image will go here */}
                </div>
              </div>
            </div>
          </div>
          <div className="grid grid-cols-3 mx-12 mt-16">
            <button
              type="submit"
              className="bg-red-500 text-white p-2 rounded hover:bg-red-600 transition"
              onClick={() => declineApplication(application)}
            >
              Decline
            </button>
            <div>
              {/* This div ensures approve and deny buttons are seperated*/}
            </div>
            <button
              type="submit"
              className="bg-green-500 text-white p-2 rounded hover:bg-green-600 transition"
              onClick={() => approveApplication(application)}
            >
              Approve
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
